import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSWR, { mutate } from "swr";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "components/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import ChangePasswordDialog from "components/ChangePasswordDialog";
import Button from "components/Button";
import Main from "components/Main";
import { Link } from "react-router-dom";
import { useConfirm } from "components/ConfirmDialog";

const DeleteAccount = () => {
  const { deleteUser } = useAuth();
  const [confirmConversion, confirmConversionDialog] = useConfirm();
  return (
    <>
      <Button
        label="Supprimer mon compte"
        variant="noborder"
        color="red"
        className="underline"
        onClick={() =>
          confirmConversion({
            text: "Êtes-vous sûr de vouloir supprimer votre compte ?",
            subText: "Toute suppression est irréversible.",
            action: () => deleteUser(),
          })
        }
      />
      {confirmConversionDialog}
    </>
  );
};

const MyAccount = () => {
  const { isCustomerUrl, isPartnerUrl } = useConfig(); // TODO: Cacher les CGU si partenaire
  const [fetchApi] = useFetchApi();
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const [body, setBody] = useState({ data: {} }); // Initialize with an empty data object
  // Callback to handle input changes and update body state
  const handleInputChange = (name, value) => {
    setBody((prevBody) => ({
      ...prevBody,
      data: {
        ...prevBody.data,
        [name]: value,
      },
    }));
  };
  const { isCustomer, isManager } = useAuth();
  const [editing, setEditing] = useState(false);
  // Fetch data using useSWR
  const { data } = useSWR("users/me", fetchApi);

  const onSubmit = async () => {
    setEditing(!editing);
    document.activeElement?.blur();
    try {
      await fetchApi(`users/${data.id}`, { body: body.data, method: "PUT" });
      // Trigger a revalidation (refetch) of the "Mes dossiers" list.
      mutate(`users/${data.id}`);
      mutate(`users/me`);
      // onClose();
    } catch ({ message }) {
      // console.log('error',message)
      // setError(message);
    } finally {
      // console.log('finally body', body)
      // console.log(data)
    }
  };
  const onCancel = () => {
    setEditing(!editing);
  };

  // Update the body state with fetched data when it changes
  useEffect(() => {
    if (data) {
      const { telephone, name, firstname, email } = data;
      setBody({
        data: {
          telephone,
          name,
          firstname,
          email,
        },
      });
    }
  }, [data]);

  const inputs = [
    { label: "Nom", name: "name" },
    { label: "Prénom", name: "firstname" },
    { label: "Adresse mail", name: "email" },
    { label: "Téléphone", name: "telephone", maxLength: "10" },
  ];

  return (
    <>
      <Main title="Mon profil" header={isCustomer && <DeleteAccount />}>
        <div className="w-[544px] rounded-xl border-0.5 border-stone-400 p-5">
          <div className="flex items-center justify-between">
            <h1 className="text-main-color text-base font-semibold">
              Mon profil
            </h1>
            <div>
              {editing && (
                <div className="flex space-x-1">
                  <button onClick={onSubmit}>
                    <img src={iconConfirm} alt="confirmer" />
                  </button>
                  <button onClick={onCancel}>
                    <img src={iconCancel} alt="annuler" />
                  </button>
                </div>
              )}

              {!editing && isManager && (
                <div className="flex space-x-1">
                  <button onClick={() => setEditing(!editing)}>
                    <img src={iconEdit} alt="modifier" />
                  </button>
                </div>
              )}
            </div>
          </div>
          {inputs.map((input) => (
            <EditableInput
              key={input.name}
              label={input.label}
              name={input.name}
              value={body.data[input.name] || ""} // Set initial value from body state
              onChange={handleInputChange} // Pass the callback function
              onSubmit={onSubmit}
              onCancel={onCancel}
              editing={editing}
              maxLength={input.maxLength}
            />
          ))}
        </div>
        <div className="w-[544px] mt-8">
          <Button
            label="Changer mon mot de passe"
            variant="contained"
            onClick={() => setShowChangePasswordDialog(true)}
          />
        </div>
        {showChangePasswordDialog ? (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center">
            <ChangePasswordDialog
              onClose={() => setShowChangePasswordDialog(false)}
            />
          </div>
        ) : null}
      </Main>

      <div className="flex gap-3 mt-2 text-xs underline">
        <p>
          <Link to="/cgu">CGU</Link>
        </p>
        <p>
          {isCustomerUrl || isPartnerUrl ? (
            <Link
              target={"_blank"}
              to="https://www.soregies.fr/particuliers/primes-et-financements/conditions-generales-de-ventes-cgv-de-loffre-cee/"
            >
              CGV
            </Link>
          ) : (
            <Link to="/cgv">CGV</Link>
          )}
        </p>
        <p>
          <Link to="/policy">Politique de confidentialité</Link>
        </p>
        <p>
          {isCustomerUrl || isPartnerUrl ? (
            <Link
              target={"_blank"}
              to="https://www.soregies.fr/mentions-legales-simulateur-espace-beneficiaire-cee/"
            >
              Mentions légales
            </Link>
          ) : (
            <Link to="/mention">Mentions légales</Link>
          )}
        </p>
      </div>
    </>
  );
};

const EditableInput = ({
  label,
  name,
  value,
  editing,
  onChange,
  maxLength,
}) => {
  const handleInputOnChange = (event) => {
    const { name, value } = event.target;
    onChange(name, value); // Call the callback function to update the body state
  };

  return (
    <div className="text-sm mt-4 flex">
      <label className="w-[120px]">{label}: </label>
      {editing ? (
        <input
          type="text"
          name={name}
          value={value}
          onChange={handleInputOnChange}
          className="w-[350px] ml-1 border-0.5 border-stone-400 px-1 py-1"
          maxLength={maxLength || 100}
        />
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};
EditableInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  editing: PropTypes.bool,
  maxLength: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default MyAccount;
